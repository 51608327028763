import PrimaryButton from 'DesignComponents/Atoms/Buttons/PrimaryButton';
import SecondaryButton from 'DesignComponents/Atoms/Buttons/SecondaryButton';
import BodyText from 'DesignSystem/Typography/BodyText/BodyText';
import { useEffect, useRef } from 'react';
import Button from 'Shared/Components/Buttons/Button';

import {
  ModalFooterActionAreaStyled, ModalFooterActionStyled, ModalFooterProps, ModalFooterStyled
} from 'DesignComponents/Organisms/Modal/ModalFooter';
import redirect from 'Shared/Components/Links/Redirect';
import MiniCartSummary from './MiniCartSummary';
import { GetCart } from '../Cart';
import { useUiState } from 'Shared/Providers/UiState/UiStateProvider';
import { gtmPushEvent } from 'Shared/GTM/GtmPageEventHandler';
import { useAppSettings, useTranslations } from '../../../context/init-data.context';
import { useKexNavigate } from '../../../lib/router/UseKexNavigate';

const MiniCartModalFooter = ({
  getModalFooterHeight,
  displayModal,
  modalFooterContent,
}: ModalFooterProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const {
    cartLabels: { order, cartContinueShopping },
  } = useTranslations();
  const kexNavigate = useKexNavigate();
  const {
    languageRoute,
    staticPages: { checkoutPage },
  } = useAppSettings();
  const { cart, isCartEmpty } = GetCart(languageRoute);
  const { toggleModal } = useUiState();

  useEffect(() => {
    if (displayModal) {
      setTimeout(() => {
        if (getModalFooterHeight)
          getModalFooterHeight(ref.current?.offsetHeight);
      }, 310); // Wait for transition to finish then get the height from footer
    }
  }, [displayModal]);

  if (!modalFooterContent) return null;

  function handleGoToCheckout() {
    if (!isCartEmpty) {
      gtmPushEvent(cart?.beginCheckoutEvent);
    }

    toggleModal(false);

    if (!isCartEmpty) {
      redirect({
        kexNavigate,
        target: '_self',
        url: checkoutPage ?? '',
      });
    }
  }

  return (
    <ModalFooterStyled ref={ref} footerLayout={modalFooterContent.footerLayout}>
      {modalFooterContent.footerLayout === 'stacked' && cart && (
        <MiniCartSummary cart={cart} readOnly={false} />
      )}

      <ModalFooterActionAreaStyled
        footerLayout={modalFooterContent.footerLayout}
      >
        <ModalFooterActionStyled footerLayout={modalFooterContent.footerLayout}>
          <Button<typeof PrimaryButton>
            element={'PrimaryButton'}
            props={{
              text: isCartEmpty ? cartContinueShopping : order,
              color: 'Regular',
              hug:
                modalFooterContent.footerLayout === 'stacked'
                  ? 'width'
                  : undefined,
            }}
            onClick={handleGoToCheckout}
          />
        </ModalFooterActionStyled>
        {modalFooterContent.secondaryButton && (
          <ModalFooterActionStyled>
            <Button<typeof SecondaryButton>
              element={'SecondaryButton'}
              props={{
                text: modalFooterContent.secondaryButton.text,
                color: 'Regular',
                hug:
                  modalFooterContent.footerLayout === 'stacked'
                    ? 'width'
                    : undefined,
              }}
              onClick={modalFooterContent.secondaryButton?.onClick}
            />
          </ModalFooterActionStyled>
        )}
      </ModalFooterActionAreaStyled>
      {modalFooterContent.text &&
        modalFooterContent.footerLayout === 'sideBySide' && (
          <BodyText>{modalFooterContent.text}</BodyText>
        )}
    </ModalFooterStyled>
  );
};

export default MiniCartModalFooter;
